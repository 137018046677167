.overlay {
  z-index: 10000;
  top: 0;
  position: fixed;
  width: 100%;
  height: 100%;

  .background {
    background: rgba(0, 0, 0, 0.75);
    width: 100%;
    height: 100%;
  }
}
.custom-modal {
  padding: 32px 0;
  width: 500px;
  background: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  .custom-modal-message p {
    text-align: center;
    font-size: 12px;
    margin: 12px 0 0 0;
  }

  :global(.cb-loader.cb-loader-lg) {
    margin: 0 auto;
  }

  :global(.cb-loader.cb-loader-lg),
  :global(.cb-loader.cb-loader-lg::before) {
    width: 80px;
    height: 80px;
  }

  :global(.cb-loader.cb-loader-lg::after) {
    content: "processing";
  }
}

:global(.alert.alert-danger.cb-alert-heading) {
  ul {
    li {
      margin-left: 16px;
      a,
      a:active a:link,
      a:visited,
      a:hover {
        color: #c13145;
        text-decoration: underline;
      }
    }
  }
}

:global(.cb-input),
:global(.cb-select) {
  display: flex;
  flex-direction: column;
  align-items: flex-start !important;
}

:global .ecom-tooltip {
  min-width: 350px;
  .cb-tooltip-inner {
    padding: 0;
  }
  .cb-tip-header {
    margin-bottom: 16px;
    text-align: left;
    background-color: #F0F0F0;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    padding: 16px;
    
    .cb-tip-title {
      font-size: 1.4375rem;
      font-weight: 400;
      line-height: 1.0435em;
    }
  }
  .cb-tip-content {
    padding: 16px;

  }
}

:global .ecom-tip-trigger {
  margin-left: 8px;
}

:global .cb-card {
  padding-bottom: 48px!important;
  padding-top: 48px!important;
}